import * as React from "react"
import Seo from "../components/shell/seo"
import LayoutWrapper from "../template/LayoutWrapper"

const NotFoundPage = (props) => (
  <LayoutWrapper pageContext={props.pageContext}>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </LayoutWrapper>
)

export default NotFoundPage
